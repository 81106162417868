<template>
  <div></div>
</template>

<script >
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";
import tools from "../commons/tools.js";
export default defineComponent({
  name: "Home",
  data() {
    return {
      loading: null,
      LanguageId: "",
      clientHeight: document.documentElement.clientHeight,
    };
  },
  mounted() {
 
    this.loading = ElLoading.service({
      lock: true,
      text: "Loading",
      background: "rgba(0, 0, 0, 0.7)",
    });

    this.LanguageId = localStorage.getItem("LanguageId");

    if (
      this.LanguageId == "" ||
      this.LanguageId == null ||
      this.LanguageId == "null" ||
      this.LanguageId == undefined
    ) {
      tools.GetLanguageDefault(() => {
        var url = "storeLogin";
        if (window.location.href.indexOf("sassadmin") > -1 || window.location.href.indexOf("admin") > -1) {
          url = "adminLogin";
        }
        this.loading.close();
        this.$router.push({
          path: url,
        });
      });
    } else {
      tools.GetLanguageById(this.LanguageId, () => {
        this.loading.close();
        var url = "storeLogin";
        if (window.location.href.indexOf("sassadmin") > -1 || window.location.href.indexOf("admin") > -1) {
          url = "adminLogin";
        }
        this.loading.close();
        this.$router.push({
          path: url,
        });
        // this.$router.push({
        //   path: "storeLogin",
        // });
      });
    }
  },

  methods: {},
  components: {},
});
</script>
